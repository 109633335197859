// https://developer.adobe.com/commerce/webapi/graphql-api/index.html#definition-FilterEqualTypeInput

export const FilterEqualTypeInput = [
  "activity",
  "category_gear",
  "category_id",
  "category_uid",
  "category_url_path",
  "climate",
  "collar",
  "color",
  "eco_collection",
  "erin_recommends",
  "features_bags",
  "format",
  "gender",
  "material",
  "new",
  "pattern",
  "performance_fabric",
  "purpose",
  "sale",
  "size",
  "sku",
  "sleeve",
  "strap_bags",
  "style_bags",
  "style_bottom",
  "style_general",
  "url_key",
  // "category_master",
  // "color",
  // "frame",
  // "style",
];

export const FilterMatchTypeInput = [
  "description",
  "name",
  "short_description",
];
export const FilterRangeTypeInput = ["price"];
