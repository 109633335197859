/* eslint-disable @next/next/no-img-element */
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "~/components/Button";
import Container from "~/components/Container";
import WishButton from "~/components/WishButton";
import { MegaMenuType } from "~/constants/enum";
import { AppContext } from "~/contexts/app.context";
import { megaMenuAtom } from "~/services/megaMenu";
import { recentKeywordState } from "~/services/recent-search/recent-search.atom";
import storeConfigAtom from "~/services/store-config/store-config.atom";
import { getTheme } from "~/utils/theme";
import SearchFilter from "../SearchFilter";
import styles from "./Header.module.scss";

const MobileHeader = dynamic(() => import("./MobileHeader"), { ssr: false });
const CartButton = dynamic(() => import("~/components/CartButton"), {
  ssr: false,
});
const UserButton = dynamic(() => import("~/components/UserButton"), {
  ssr: false,
});
const TopHeader = dynamic(() => import("~/components/TopHeader"), {
  ssr: false,
});

const Navigation = dynamic(async () => {
  try {
    return await (
      require(`~/theme/${getTheme()}/sections/Navigation`)
    );
  } catch (error) {
    return await require(`~/sections/Navigation`);
  }
})


interface HeaderProps {
  menuItems?: { id: string; label: string; url: string }[];
  banner?: ReactNode; // Pass null to hide the banner
}

const Header: FC<HeaderProps> = ({
  menuItems = [],
  banner,
}) => {
  const router = useRouter();
  const { openSearch, setOpenSearch } = useContext(AppContext);
  const refHeader = useRef<any>()
  const recoilRecentKeywords = useRecoilValue(recentKeywordState);

  const [openMenu, setOpenMenu] = useState(false);
  const storeConfig = useRecoilValue(storeConfigAtom.store)
  const megaMenu = useRecoilValue(megaMenuAtom.megaMenu)
  const setMegaMenu = useSetRecoilState(megaMenuAtom.megaMenu)
  const [recentKeywords, setRecentKeywords] = useState<string[]>();


  const handleOpenSearch = () => {
    setTimeout(() => {
      setOpenSearch(!openSearch);
    }, 0);
  };
  const dataSubmenu: any = useMemo(() => {
    if (megaMenu.subMenuHover) {
      return megaMenu.menu?.find((item) => item.node_id === megaMenu.subMenuHover && item.type === MegaMenuType.Wrapper)
    }
    return null
  }, [megaMenu.subMenuHover])

  // useEffect(() => {
  //   if(refHeader.current && !!megaMenu?.menu?.length){
  //     const { height } = refHeader.current.getBoundingClientRect();
  //     setMegaMenu((prev) => ({
  //       ...prev,
  //       headerHeight: height
  //     }))
  //   }
  // }, [refHeader.current])

  const handleCloseDropdown = useCallback(() => {
    if (!megaMenu.subMenuHover) return null
    setMegaMenu((prev) => ({
      ...prev,
      subMenuHover: ""
    }))
  }, [megaMenu.subMenuHover])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRecentKeywords(recoilRecentKeywords)
    }
  }, [recoilRecentKeywords])


  return (
    <>
      {
        <TopHeader />
      }
      <div className={styles.header} ref={refHeader}>
        <Container className="flex items-center justify-around relative">
          <div className="flex items-center gap-1">
            <Button
              className="flex lg:!hidden"
              prefixIcon="menu1"
              shape="square"
              type="borderless"
              onClick={() => setOpenMenu(true)}
              aria-label="Menu"
            />
            <Link href="/" className="h-10">
              {
                storeConfig?.frontend_config_logo &&
                <img
                  src={storeConfig?.frontend_config_logo || ''}
                  alt="Logo"
                  className="h-full object-contain object-left"
                  width={140}
                  height={40}
                />
              }
            </Link>
          </div>

          <div className="flex-1  gap-[56px] text-center">
            {/* {menuItems.map((item) => (
            <Link key={item.id} href={item.url || "#"}>
              <Text type="body-2">{item.label}</Text>
            </Link>
          ))} */}
            <div className="hidden md:flex  ">
              <Navigation />
            </div>
          </div>

          <div className="flex ">
            <Button
              prefixIcon={
                <img src="/svgs/search.svg" width={24}  height={24} alt="Search" />
              }
              shape="square"
              type="borderless"
              onClick={handleOpenSearch}
              aria-label="Search"
            />
            <UserButton />

            <WishButton />
            <CartButton />
          </div>

          {/* <div
            onMouseLeave={handleCloseDropdown}
          >
            {!!dataSubmenu?.submenu && (
              <div
                className={classNames(stylesMenuDropDow.menu_dropdown, "dropdown-animation", {
                  "dropdown-animation-show": true,
                  [stylesMenuDropDow.fullWidth]: dataSubmenu.type === MegaMenuType.Wrapper,
                  ["w-[150%]"]: dataSubmenu.type !== MegaMenuType.Wrapper,
                })}
                style={{
                  top: 72
                }}
              >
                <div className="flex flex-col gap-2 relative" >
                  {dataSubmenu.submenu.map((submenuItem: any, index: number) => {
                    return (
                      <Fragment key={submenuItem.id || v4()}>
                        <MenuDropdown data={submenuItem} />
                        {index < dataSubmenu.submenu.length - 1 && <Divider />}

                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </div> */}
        </Container>
       
        <SearchFilter
          recentKeywords={recentKeywords?.slice(0, 5)}
        />
        <MobileHeader open={openMenu} onClose={() => setOpenMenu(false)} />
      </div>
    </>
  );
};

export default Header;
