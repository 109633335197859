import classNames from "classnames";
import { CSSProperties, FC, ReactNode } from "react";

interface ContainerProps {
  className?: string;
  children?: ReactNode;
  rootClassName?: string;
  rootStyle?: CSSProperties;
  extra?: ReactNode;
  id?: string;
}

const Container: FC<ContainerProps> = ({
  className,
  children,
  rootClassName,
  rootStyle,
  extra,
  id,
}) => {
  return (
    <div id={id} className={rootClassName} style={rootStyle}>
      {extra}
      <div
        className={classNames(
          "w-full max-w-[1328px] mx-auto px-2 lg:px-4 relative",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
