import Colors from "~/constants/colors";

const config = {
  Common: { starActiveColor: Colors.ACCENT_ORANGE },
  ProductItem: {
    showDivider: true,
    starBreakSize: ["medium", "small"],
    thumbnailRadius: 0,
    badgeStyle: "sitcked",
  },
  ProductDetail: {
    badgeStyle: "sitcked",
  },
  Section: {
    dividerPlacement: "bottom",
  },
  NewCollection: {
    showItemTitle: true,
  },
};

export default config;
