const Colors = {
    PRIMARY: 'var(--primary)',
    BLACK: '#000000',
    PRIMARY_LIGHT: '#fff0f1',
    GREY_00: '#FFFFFF',
    GREY_10: '#808080',
    GREY_50: '#f1f1f1',
    GREY_100: '#d3d3d3',
    GREY_200: '#bdbdbd',
    GREY_300: '#9f9f9f',
    GREY_400: '#8d8d8d',
    GREY_500: '#707070',
    GREY_600: '#666666',
    GREY_700: '#505050',
    GREY_800: '#3e3e3e',
    GREY_900: '#2f2f2f',
    ACCENT_YELLOW: '#fcd354',
    ACCENT_ORANGE: '#ffb125',
    ACCENT_ORANGE_50: '#ED5700',
    ACCENT_GREEN: '#2fb53d',
    ACCENT_GREEN_50: '#3BA55C',
    ACCENT_BLUE: '#4987ed',
    ACCENT_BLUE_50: '#364DE7',
    ACCENT_VIOLET: '#702DFF',
    ACCENT_RED: '#ED4245',
    ACCENT_RED_50: '#E04D4D',
    ACCENT_RED_100: '#FFF0F1',
    HEADER_BG: '#0F0F0F',
    FAVORITE_COLOR: 'var(--favorite-icon-color)',
    DIVIDER_COLOR: 'var(--section-title-divider-color)'
};

export default Colors;
